import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import BorderedButton from "../../../../button/BorderedButton"
import { primaryDark, primaryLight } from "../../../../../constants/colors"
import { Swiper, SwiperSlide } from "swiper/react"
import { A11y, Navigation, Pagination, Scrollbar } from "swiper"
import { desktop, largeDesktop } from "../../../../../constants/screenSizes"
import { PrimaryButton } from "components"
import { Link, navigate } from "gatsby"

const Container = styled.div`
  display: flex;
  margin: 20px;
  max-width: 2000px;

  @media (max-width: ${largeDesktop}px) {
    margin: 20px 0;
  }

  @media (min-width: 2000px) {
    margin: 20px auto;
  }

  .swiper-slide {
    align-self: stretch;
    display: flex;
  }
`
const Option = styled.div`
  flex: 1;
  background-color: #f7f9f7;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  img {
    cursor: pointer;
  }

  @media (max-width: ${largeDesktop}px) {
    margin-bottom: 50px;
  }
`
const Body = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`
const Title = styled.div`
  font-weight: bold;
`
const Description = styled.div`
  margin-bottom: 20px;
`
const SecondaryButton = styled(BorderedButton)`
  margin-bottom: 10px;
`

const Buttons = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
`

export interface OptionType {
  title: string
  description: string
  imageSrc: string
  primaryOnClick?: () => void
  primaryLabel?: string
  secondaryOnClick?: () => void
  secondaryLabel?: string
}

interface Props {
  options: OptionType[]
}

const LPOptionsFeatureBlock: FC<Props> = ({ options }) => {
  return (
    <Container>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={20}
        slidesOffsetBefore={20}
        slidesOffsetAfter={20}
        slidesPerView={1.3}
        onSlideChange={() => console.log("slide change")}
        onSwiper={swiper => console.log(swiper)}
        breakpoints={BREAK_POINTS}
        pagination={{ clickable: true }}
      >
        {options.map(option => (
          <SwiperSlide>
            <Option>
              <img src={option.imageSrc} alt={option.title} onClick={option.secondaryOnClick} />

              <Body>
                <Title>{option.title}</Title>
                <Description>{option.description}</Description>
                <Buttons>
                  {option.secondaryOnClick && (
                    <SecondaryButton
                      onClick={option.secondaryOnClick}
                      color={primaryDark}
                      secondaryColor={"white"}
                      slim
                    >
                      {option.secondaryLabel}
                    </SecondaryButton>
                  )}
                  {option.primaryOnClick && (
                    <PrimaryButton invert slim onClick={option.primaryOnClick}>
                      {option.primaryLabel}
                    </PrimaryButton>
                  )}
                </Buttons>
              </Body>
            </Option>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  )
}

const BREAK_POINTS = {
  [750]: {
    slidesPerView: 2.3,
    slidesOffsetAfter: 20,
  },
  [largeDesktop]: {
    slidesPerView: 3,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    pagination: false,
  },
}

export default LPOptionsFeatureBlock
